import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const plans = {
  '2-bags-monthly-1-month': {
    'product_name': '2 Bags / Month (1 Month)',
    'product_price': 2300
  },
  '2-bags-monthly-3-month': {
    'product_name': '2 Bags / Month (3 Month)',
    'product_price': 6600
  },
  '2-bags-monthly-6-month': {
    'product_name': '2 Bags / Month (6 Month)',
    'product_price': 12600
  },
  '2-bags-monthly-12-month': {
    'product_name': '2 Bags / Month (12 Month)',
    'product_price': 24000
  },
  '4-bags-monthly-1-month': {
    'product_name': '4 Bags / Month (1 Month)',
    'product_price': 3400
  },
  '4-bags-monthly-3-month': {
    'product_name': '4 Bags / Month (3 Month)',
    'product_price': 9900
  },
  '4-bags-monthly-6-month': {
    'product_name': '4 Bags / Month (6 Month)',
    'product_price': 19200
  },
  '4-bags-monthly-12-month': {
    'product_name': '4 Bags / Month (12 Month)',
    'product_price': 37200
  },
  '6-bags-monthly-1-month': {
    'product_name': '6 Bags / Month (1 Month)',
    'product_price': 4800
  },
  '6-bags-monthly-3-month': {
    'product_name': '6 Bags / Month (3 Month)',
    'product_price': 14100
  },
  '6-bags-monthly-6-month': {
    'product_name': '6 Bags / Month (6 Month)',
    'product_price': 27600
  },
  '6-bags-monthly-12-month': {
    'product_name': '6 Bags / Month (12 Month)',
    'product_price': 52800
  },
  '8-bags-monthly-1-month': {
    'product_name': '8 Bags / Month (1 Month)',
    'product_price': 5800
  },
  '8-bags-monthly-3-month': {
    'product_name': '8 Bags / Month (3 Month)',
    'product_price': 17100
  },
  '8-bags-monthly-6-month': {
    'product_name': '8 Bags / Month (6 Month)',
    'product_price': 33000
  },
  '8-bags-monthly-12-month': {
    'product_name': '8 Bags / Month (12 Month)',
    'product_price': 64800
  },
  'keto-box-1-month': {
    'product_name': 'Keto Box (1 Month)',
    'product_price': 4900
  },
  'keto-box-3-month': {
    'product_name': 'Keto Box (3 Month)',
    'product_price': 14700
  },
  'keto-box-6-month': {
    'product_name': 'Keto Box (6 Month)',
    'product_price': 29400
  },
  'keto-box-12-month': {
    'product_name': 'Keto Box (12 Month)',
    'product_price': 58800
  },
  'booze-box-1-month': {
    'product_name': 'Booze Jerky Subscription (1 Month)',
    'product_price': 5900
  },
  'booze-box-3-month': {
    'product_name': 'Booze Jerky Subscription (3 Month)',
    'product_price': 17100
  },
  'booze-box-6-month': {
    'product_name': 'Booze Jerky Subscription (6 Month)',
    'product_price': 34200
  },
  'booze-box-12-month': {
    'product_name': 'Booze Jerky Subscription (12 Month)',
    'product_price': 67200
  },
  'gluten-free-box-1-month': {
    'product_name': 'Gluten Free Box (1 Month)',
    'product_price': 5900
  },
  'gluten-free-box-3-month': {
    'product_name': 'Gluten Free Box (3 Month)',
    'product_price': 17700
  },
  'gluten-free-box-6-month': {
    'product_name': 'Gluten Free Box (6 Month)',
    'product_price': 35400
  },
  'gluten-free-box-12-month': {
    'product_name': 'Gluten Free Box (12 Month)',
    'product_price': 70800
  },
  'hot-box-1-month': {
    'product_name': 'Hot & Spicy Box (1 Month)',
    'product_price': 4900
  },
  'hot-box-3-month': {
    'product_name': 'Hot & Spicy Box (3 Month)',
    'product_price': 14700
  },
  'hot-box-6-month': {
    'product_name': 'Hot & Spicy Box (6 Month)',
    'product_price': 29400
  },
  'hot-box-12-month': {
    'product_name': 'Hot & Spicy Box (12 Month)',
    'product_price': 58800
  }
}

export default new Vuex.Store({
  state: {
    plans,
    plan_id: '',
    renew: true,
    send_on: null,
    invoice: null,
  },
  mutations: {
    mutatePlanID(state, payload) {
      state.plan_id = payload;
    },
    mutateRenew(state, payload) {
      state.renew = payload;
    },
    mutateSendOn(state, payload) {
      state.send_on = payload;
    },
    mutateInvoice(state, payload) {
      state.invoice = payload;
    }
  },
  actions: {
  },
  modules: {
  }
})
