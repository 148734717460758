import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      // console.log(to.query.plan_id);

      // console.log(to.query.ts);
      // console.log(to.query.renew);
      store.commit('mutatePlanID', to.query.plan_id);
      store.commit('mutateSendOn', to.query.ts);
      store.commit('mutateRenew', to.query.renew);
      

      next();
    }
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import('../views/Thanks.vue')
  },
  {
    path: '/cbtest',
    name: 'cbtest',
    component: () => import('../views/Cbtest.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
