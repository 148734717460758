<template>
  <div class="payment-subform">
    <div>
      <div
        id="conditional-cc-form"
        class="field-group field-group--has-subfields"
      >
        <div class="field field--required">
          <input
            tabindex="0"
            autocomplete="cc-name"
            data-persist="credit_card_name"
            id="checkout_credit_card_name"
            name="checkout[credit_card][name]"
            placeholder="Name on card"
            size="30"
            class=""
            type="text"
            value=""
          />
        </div>

        <div id="cc-form" class="field field--required">
          
          <fieldset class="spf-fs-cc">
            <div class="spf-field">
              <div
                id="spreedly-number-test"
                class="spf-field-group spf-number spf-field-cc"
                style="height: 50px; margin-top: 10px; padding-top: 10.5px"
              ></div>
            </div>
          </fieldset>

          <i
            class="field__icon icon icon--lock-dark has-tooltip card-number-tooltip"
          >
            <span class="tooltip tooltip--right-on-mobile">
              All transactions are secure and encrypted.
            </span>
          </i>
        </div>

        <div class="field field--required">
          <div class="select-group">
            <select
              data-persist="credit_card_month"
              autocomplete="cc-exp-month"
              id="checkout_credit_card_month"
              role="selectgroup"
              aria-label="Expiration (Month)"
            >
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            <select
              data-persist="credit_card_year"
              id="checkout_credit_card_year"
              autocomplete="cc-exp-year"
              role="selectgroup"
              aria-label="Expiration (Year)"
            >
              <option value="2020" selected="">2020</option>

              <option value="2021">2021</option>

              <option value="2022">2022</option>

              <option value="2023">2023</option>

              <option value="2024">2024</option>

              <option value="2025">2025</option>

              <option value="2026">2026</option>

              <option value="2027">2027</option>

              <option value="2028">2028</option>

              <option value="2029">2029</option>

              <option value="2030">2030</option>

              <option value="2031">2031</option>

              <option value="2032">2032</option>

            </select>
          </div>
        </div>

        <div class="field field--required">
          <fieldset class="spf-fs-cc">
            <div class="spf-field">
              <div
                id="spreedly-cvv-test"
                class="spf-field-group spf-verification_value spf-field-cc"
                style="height: 50px; margin-top: 10px; padding-top: 10.5px"
              ></div>
            </div>
          </fieldset>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@media all {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  input,
  select {
    color: inherit;
    font: inherit;
    margin: 0;
  }
  select {
    text-transform: none;
  }
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  input {
    line-height: normal;
  }
  input[type="radio"] {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }

  label {
    color: #555;
    cursor: pointer;
    font-weight: 500;
    line-height: 1.5em;
  }
  input,
  select {
    color: #777;
  }
  input:focus,
  select:focus {
    outline: none;
  }
  input[type="tel"],
  input[type="text"],
  select {
    background: transparent;
    border: none;
    line-height: 1.5em;
    margin: 0;
    padding: 0;
  }
  input[type="tel"]::-ms-clear,
  input[type="text"]::-ms-clear,
  select::-ms-clear {
    display: none;
  }
  ::-webkit-input-placeholder {
    color: #ccc;
  }
  :-moz-placeholder,
  ::-moz-placeholder {
    color: #ccc;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #ccc;
  }
  .boxsizing select {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-right: 2em;
  }

  input[type="radio"] {
    float: left;
  }
  .chrome input[type="radio"] {
    -webkit-appearance: none;
    -webkit-backface-visibility: hidden;
    -webkit-transition: box-shadow 0.3s cubic-bezier(0.3, 0, 0, 1.2),
      background-color 0.3s cubic-bezier(0.3, 0, 0, 1.2),
      border-color 0.3s cubic-bezier(0.3, 0, 0, 1.2);
    backface-visibility: hidden;
    border: 1px solid #ccc;
    border-radius: 50%;
    box-shadow: inset 0 0 0 0 #2a9dcc;
    cursor: pointer;
    display: block;
    height: 20px;
    margin: 0;
    transition: box-shadow 0.3s cubic-bezier(0.3, 0, 0, 1.2),
      background-color 0.3s cubic-bezier(0.3, 0, 0, 1.2),
      border-color 0.3s cubic-bezier(0.3, 0, 0, 1.2);
    width: 20px;
  }

  .chrome input[type="radio"]:hover {
    border-color: #b3b3b3;
  }
  .chrome input[type="radio"]:focus {
    outline: none;
  }
  .has-tooltip {
    position: relative;
  }
  .has-tooltip:hover .tooltip {
    -webkit-transform: rotateX(0deg) scale(1);
    opacity: 1;
    pointer-events: auto;
    transform: rotateX(0deg) scale(1);
    visibility: visible;
  }
  .tooltip {
    -moz-box-sizing: border-box;
    -webkit-transform: rotateX(20deg) scale(0.8);
    -webkit-transform-origin: center 120%;
    -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0, 1.2);
    background: #424242;
    border-radius: 5px;
    bottom: 120%;
    box-sizing: border-box;
    color: #fff;
    display: block;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    left: 50%;
    line-height: 1.4em;
    margin: 0 0 5px -90px;
    opacity: 0;
    padding: 0.75em 1em;
    pointer-events: none;
    position: absolute;
    text-align: center;
    text-indent: 0;
    transform: rotateX(20deg) scale(0.8);
    transform-origin: center 120%;
    transition: all 0.3s cubic-bezier(0.3, 0, 0, 1.2);
    visibility: hidden;
    white-space: normal;
    width: 180px;
    z-index: 200;
  }
  .rgba .tooltip {
    background: rgba(68, 68, 68, 0.9);
  }
  .tooltip:after {
    border: 7px solid transparent;
    border-top: 5px solid rgba(68, 68, 68, 0.9);
    content: "";
    left: 50%;
    margin-left: -7px;
    position: absolute;
    top: 100%;
    width: 0;
  }
  i {
    background-position: 50%;
    background-repeat: no-repeat;
    display: inline-block;
    text-indent: 100%;
    white-space: nowrap;
  }
  .icon--lock-dark {
    height: 16px;
    vertical-align: sub;
    width: 16px;
  }
  .icon--lock-dark {
    background-position: 50%;
    background-repeat: no-repeat;
  }
  .icon--lock-dark {
    background-image: url('../assets/lock-gray.svg'),
      none;
    margin-top: -8px;
  }
  .icon--info {
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .fieldset {
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    margin-bottom: 1.5em;
  }
  .fieldset:last-child {
    margin-bottom: 0;
  }
  .field {
    -webkit-transition: box-shadow 0.1s cubic-bezier(0.3, 0, 0, 1.2),
      border-color 0.1s cubic-bezier(0.3, 0, 0, 1.2),
      border-radius 0.1s cubic-bezier(0.3, 0, 0, 1.2);
    border-top: 1px solid #dfdfdf;
    padding-left: 1em;
    padding-right: 1em;
    position: relative;
    transition: box-shadow 0.1s cubic-bezier(0.3, 0, 0, 1.2),
      border-color 0.1s cubic-bezier(0.3, 0, 0, 1.2),
      border-radius 0.1s cubic-bezier(0.3, 0, 0, 1.2);
    zoom: 1;
    background: white;
  }
  .field:after,
  .field:before {
    content: "";
    display: table;
  }
  .field:after {
    clear: both;
  }
  .fieldset > .field:first-child {
    border-top: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .fieldset > .field-group:last-child,
  .fieldset > .field-group:last-child > .field:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .field__icon {
    position: absolute;
    right: 1em;
    top: 50%;
  }
  .field--has-radio-button {
    padding-left: 3.125em;
  }
  .field--has-radio-button input[type="radio"] {
    left: 1em;
    position: absolute;
    top: 1em;
  }
  .field input[type="tel"],
  .field input[type="text"],
  .field label,
  .field select {
    padding-bottom: 1em;
    padding-top: 1em;
  }
  .field label {
    float: left;
    width: 35%;
  }
  .field label .icon {
    margin-left: 0.5em;
  }
  .field .label--full-width {
    width: 100%;
  }
  .select-group,
  input[type="tel"],
  input[type="text"],
  select {
    float: left;
    width: 100%;
  }
  .select-group {
    position: relative;
  }
  select#checkout_credit_card_month:focus {
    color: black;
  }
  select#checkout_credit_card_year:focus {
    color: black;
  }
  .select-group:after {
    background: #e5e5e5;
    content: "";
    display: block;
    height: 24px;
    left: 50%;
    margin-left: -0.5px;
    margin-top: -12px;
    position: absolute;
    top: 50%;
    width: 1px;
  }
  .select-group select {
    float: right;
    width: 44%;
  }
  .select-group select:first-child {
    float: left;
  }
  .field-group--has-subfields {
    background-color: #f9f9f9;
  }
  @media (min-width: 600px) {
    .payment-methods .payment-method {
      display: inline-block;
    }
    .payment-methods .payment-method.generic {
      display: none;
    }
    .field {
      padding-left: 1.25em;
      padding-right: 1.25em;
    }
    .field__icon {
      right: 1.25em;
    }
    .field--has-radio-button {
      padding-left: 3.375em;
    }
    .field--has-radio-button input[type="radio"] {
      left: 1.25em;
      top: 1.25em;
    }

  }

}

</style>