<template>
  <div id="app ">
    <div class="container mb-3">
      <div class="pb-5 pt-5">
        <img
          src="https://d3a1v57rabk2hm.cloudfront.net/signaturejerky/bold_mobile-copy-43/images/craft-jerky-co-2.svg"
          width="300"
        />
      </div>

      <div class="row">
        <div
          id="orderSummaryMobile"
          class="col-md-4 d-block d-sm-none bg-light p-0"
          v-if="!loadingCart"
        >
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between lh-condensed border-0 bg-transparent"
            >
              <div>
                <h6 class="my-0">Total (USD)</h6>

                <small class="text-primary" @click="toggleSummary"
                  >More details</small
                >
              </div>

              <span class="text-muted">{{ amount_due | currency }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between lh-condensed border-0 bg-transparent"
              v-if="expanded_total"
            >
              <div>
                <h6 class="my-0">{{ product_name }}</h6>
                <small class="text-muted"
                  >First Shipment:
                  {{ this.$route.query.ts | formatDate }}</small
                >
              </div>
              <span class="text-muted">{{ product_price | currency }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between lh-condensed border-0 bg-transparent"
              v-if="expanded_total"
            >
              <div>
                <h6 class="my-0">Shipping</h6>
                <small class="text-muted"></small>
              </div>
              <span class="text-muted">$0.00</span>
            </li>

            <li
              class="list-group-item d-flex justify-content-between lh-condensed border-0 bg-transparent"
              v-if="expanded_total"
            >
              <div>
                <h6 class="my-0">Tax</h6>
                <small class="text-muted"></small>
              </div>
              <span class="text-muted">${{ tax_amount | currency }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between lh-condensed border-0 bg-transparent"
              v-if="expanded_total && discount_amount == 0"
            >
              <div v-if="showPromoInput">
                <div class="input-group">
                  <input
                    type="text"
                    v-model="coupon"
                    class="form-control"
                    placeholder="Promo code"
                  />

                  <div class="input-group-append">
                    <a
                      href="#"
                      class="btn btn-secondary"
                      @click="couponHandler"
                      v-if="!validatingCoupon"
                    >
                      Redeem
                    </a>

                    <a
                      href="#"
                      class="btn btn-secondary"
                      @click="couponHandler"
                      v-if="validatingCoupon"
                    >
                      <img src="@/assets/rings.svg" height="12" />
                    </a>
                  </div>
                </div>
                <div class="error-text mt-1" v-if="invalidCoupon">
                  Invalid Coupon
                </div>
              </div>
              <div class="text-center pb-2" v-else>
                <span v-on:click="togglePromoInput">Have a promo code?</span>
              </div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between bg-light border-0 bg-transparent"
              v-if="discount_amount > 0"
            >
              <div class="">
                <h6 class="my-0">Promo code</h6>
                <small class="text-primary" @click="removeCoupon">remove</small>
              </div>
              <span class="">{{ -discount_amount | currency }}</span>
            </li>
          </ul>
        </div>
        <div
          class="col-md-4 d-block d-sm-none bg-light pt-3 text-center"
          v-else
        >
          <div class="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="col-md-4 order-md-2 mb-1 d-none d-sm-block">
          <h4
            class="d-flex justify-content-between align-items-center mb-3 border-0 bg-transparent"
          ></h4>
          <ul class="list-group mb-3" v-if="!loadingCart">
            <li
              class="list-group-item d-flex justify-content-between lh-condensed bg-light"
            >
              <div>
                <h6 class="my-0">{{ product_name }}</h6>
                <small class="text-muted"
                  >First Shipment:
                  {{ this.$route.query.ts | formatDate }}</small
                >
              </div>
              <span class="text-muted">{{ product_price | currency }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between lh-condensed"
            >
              <div>
                <h6 class="my-0">Shipping</h6>
                <small class="text-muted"></small>
              </div>
              <span class="text-muted">$0.00</span>
            </li>

            <li
              class="list-group-item d-flex justify-content-between lh-condensed"
            >
              <div>
                <h6 class="my-0">Tax</h6>
                <small class="text-muted"></small>
              </div>
              <span class="text-muted">${{ tax_amount | currency }}</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between lh-condensed"
              v-if="discount_amount == 0"
            >
              <div v-if="showPromoInput">
                <div class="input-group">
                  <input
                    type="text"
                    v-model="coupon"
                    class="form-control"
                    placeholder="Promo code"
                  />

                  <div class="input-group-append">
                    <a
                      href="#"
                      class="btn btn-secondary"
                      @click="couponHandler"
                      v-if="!validatingCoupon"
                    >
                      Redeem
                    </a>

                    <a
                      href="#"
                      class="btn btn-secondary"
                      @click="couponHandler"
                      v-if="validatingCoupon"
                    >
                      <img src="@/assets/rings.svg" height="12" />
                    </a>
                  </div>
                </div>
                <div class="error-text mt-1" v-if="invalidCoupon">
                  Invalid Coupon
                </div>
              </div>
              <div class="text-center" v-else>
                <span v-on:click="togglePromoInput">Have a promo code?</span>
              </div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between bg-light"
              v-if="discount_amount > 0"
            >
              <div class="">
                <h6 class="my-0">Promo code</h6>
                <small class="text-primary" @click="removeCoupon">remove</small>
              </div>
              <span class="">{{ -discount_amount | currency }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <span>Total (USD)</span>
              <strong>${{ amount_due | currency }}</strong>
            </li>
          </ul>
        </div>

        <div class="col-md-8 order-md-1 mt-3">
          <div id="stepOne" v-show="step == 1">
            <label for="email">Billing Email Address</label>
            <FormulateForm @submit="stepOneHandler">
              <FormulateInput
                name="Billing Email Address"
                type="text"
                label="Billing Email Address"
                v-model="billing_address.email"
                help="We'll send an order confirmation here."
                placeholder="Billing Email Address"
                validation="bail|required|email"
              />
              <hr />
              <h4 class="mb-3 mt-4 mb-3">SHIPPING ADDRESS</h4>

              <FormulateInput
                name="First Name"
                type="text"
                label="First Name"
                v-model="shipping_address.first_name"
                placeholder="First Name"
                validation="required"
              />
              <FormulateInput
                name="Last Name"
                type="text"
                label="Last Name"
                v-model="shipping_address.last_name"
                placeholder="Last Name"
                validation="required"
              />
              <FormulateInput
                name="street"
                type="text"
                label="Street"
                v-model="shipping_address.line1"
                placeholder="Street"
                validation="required"
              />
              <FormulateInput
                name="unit"
                type="text"
                label="Unit"
                v-model="shipping_address.line2"
                placeholder="Unit/Apt # (optional)"
              />
              <FormulateInput
                name="city"
                type="text"
                label="City"
                v-model="shipping_address.city"
                placeholder="City"
                validation="required"
              />

              <select
                name="state"
                class="w-100 p-3 bg-white"
                v-model="shipping_address.state_code"
                style="border: 1px solid #ccc; outline: 0"
                autocomplete="address-level1"
              >
                <option value="Alabama">Alabama</option>
                <option value="Alaska">Alaska</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Arizona">Arizona</option>
                <option value="Arkansas">Arkansas</option>
                <option value="Armed Forces Americas">
                  Armed Forces Americas
                </option>
                <option value="Armed Forces Europe">Armed Forces Europe</option>
                <option value="Armed Forces Pacific">
                  Armed Forces Pacific
                </option>
                <option value="California">California</option>
                <option value="Colorado">Colorado</option>
                <option value="Connecticut">Connecticut</option>
                <option value="Delaware">Delaware</option>
                <option value="District of Columbia">
                  District of Columbia
                </option>
                <option value="Federated States of Micronesia">
                  Federated States of Micronesia
                </option>
                <option value="Florida">Florida</option>
                <option value="Georgia">Georgia</option>
                <option value="Guam">Guam</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Idaho">Idaho</option>
                <option value="Illinois">Illinois</option>
                <option value="Indiana">Indiana</option>
                <option value="Iowa">Iowa</option>
                <option value="Kansas">Kansas</option>
                <option value="Kentucky">Kentucky</option>
                <option value="Louisiana">Louisiana</option>
                <option value="Maine">Maine</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Maryland">Maryland</option>
                <option value="Massachusetts">Massachusetts</option>
                <option value="Michigan">Michigan</option>
                <option value="Minnesota">Minnesota</option>
                <option value="Mississippi">Mississippi</option>
                <option value="Missouri">Missouri</option>
                <option value="Montana">Montana</option>
                <option value="Nebraska">Nebraska</option>
                <option value="Nevada">Nevada</option>
                <option value="New Hampshire">New Hampshire</option>
                <option value="New Jersey">New Jersey</option>
                <option value="New Mexico">New Mexico</option>
                <option value="New York">New York</option>
                <option value="North Carolina">North Carolina</option>
                <option value="North Dakota">North Dakota</option>
                <option value="Northern Mariana Islands">
                  Northern Mariana Islands
                </option>
                <option value="Ohio">Ohio</option>
                <option value="Oklahoma">Oklahoma</option>
                <option value="Oregon">Oregon</option>
                <option value="Palau">Palau</option>
                <option value="Pennsylvania">Pennsylvania</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Rhode Island">Rhode Island</option>
                <option value="South Carolina">South Carolina</option>
                <option value="South Dakota">South Dakota</option>
                <option value="Tennessee">Tennessee</option>
                <option value="Texas">Texas</option>
                <option value="Utah">Utah</option>
                <option value="Vermont">Vermont</option>
                <option value="Virgin Islands">Virgin Islands</option>
                <option value="Virginia">Virginia</option>
                <option value="Washington">Washington</option>
                <option value="West Virginia">West Virginia</option>
                <option value="Wisconsin">Wisconsin</option>
                <option value="Wyoming">Wyoming</option>
              </select>

              <FormulateInput
                name="zip_code"
                type="text"
                v-model="shipping_address.zip_code"
                label="Zip Code"
                placeholder="Zip Code"
                validation="required"
              />
              <FormulateInput type="submit" name="Continue" />
            </FormulateForm>
          </div>

          <div v-show="step == 2">
            <div class="col-md-12 d-block p-0">
              <ul class="list-group mb-3">
                <li
                  class="list-group-item d-flex justify-content-between lh-condensed"
                >
                  <div>
                    <h6 class="my-0">Billing Email:</h6>

                    <small class="text-muted">{{
                      billing_address.email
                    }}</small>
                  </div>
                  <span class="text-muted" v-on:click="step = 1">Edit</span>
                </li>
              </ul>
            </div>
            <div class="col-md-12 d-block p-0">
              <ul class="list-group mb-3">
                <li
                  class="list-group-item d-flex justify-content-between lh-condensed"
                >
                  <div>
                    <h6 class="my-0">Shipping Address</h6>

                    <small class="text-muted d-block"
                      >{{ shipping_address.first_name }}
                      {{ shipping_address.last_name }}
                    </small>
                    <small class="text-muted d-block"
                      >{{ shipping_address.line1 }}
                    </small>
                    <small class="text-muted d-block"
                      >{{ shipping_address.line2 }}
                    </small>
                    <small class="text-muted d-block"
                      >{{ shipping_address.city }}
                    </small>
                    <small class="text-muted d-block"
                      >{{ shipping_address.state_code }}
                    </small>
                    <small class="text-muted d-block"
                      >{{ shipping_address.zip_code }}
                    </small>
                  </div>
                  <span class="text-muted" v-on:click="step = 1">Edit</span>
                </li>
              </ul>
            </div>

            <h5 class="mb-4">ADD A GIFT MESSAGE (optional)</h5>
            <p class="help-text">
              Add your gift message below or click continue
            </p>
            <input
              class="form-control my-2 p-3"
              v-model="gift_recipient_name"
              placeholder="Gift Recipients Name (optional)"
            />
            <input
              class="form-control my-2 p-3"
              v-model="gift_recipient_email"
              placeholder="Gift Recipients Email (optional)"
            />
            <textarea
              class="form-control my-2 p-3"
              placeholder="Gift Note (optional)"
              v-model="gift_note"
            />
            <button
              class="btn bg-dark btn-block text-white btn-lg"
              @click="stepTwoHandler"
            >
              Continue
            </button>
          </div>
          <div v-show="step == 3">
            <div class="col-md-12 d-block p-0">
              <ul class="list-group mb-3">
                <li
                  class="list-group-item d-flex justify-content-between lh-condensed"
                >
                  <div>
                    <h6 class="my-0">Billing Email:</h6>

                    <small class="text-muted">{{
                      billing_address.email
                    }}</small>
                  </div>
                  <span class="text-muted" v-on:click="step = 1">Edit</span>
                </li>
              </ul>
            </div>
            <div class="col-md-12 d-block p-0">
              <ul class="list-group mb-3">
                <li
                  class="list-group-item d-flex justify-content-between lh-condensed"
                >
                  <div>
                    <h6 class="my-0">Shipping Address</h6>

                    <small class="text-muted d-block"
                      >{{ shipping_address.first_name }}
                      {{ shipping_address.last_name }}
                    </small>
                    <small class="text-muted d-block"
                      >{{ shipping_address.line1 }}
                    </small>
                    <small class="text-muted d-block"
                      >{{ shipping_address.line2 }}
                    </small>
                    <small class="text-muted d-block"
                      >{{ shipping_address.city }}
                    </small>
                    <small class="text-muted d-block"
                      >{{ shipping_address.state_code }}
                    </small>
                    <small class="text-muted d-block"
                      >{{ shipping_address.zip_code }}
                    </small>
                  </div>
                  <span class="text-muted" v-on:click="step = 1">Edit</span>
                </li>
              </ul>
            </div>
            <div class="col-md-12 d-block p-0">
              <ul class="list-group mb-3">
                <li
                  class="list-group-item d-flex justify-content-between lh-condensed"
                >
                  <div>
                    <h6 class="my-0 py-1">Gift Note</h6>

                    <small class="text-muted"
                      >{{ gift_recipient_name }}
                      {{ gift_recipient_email }}
                    </small>
                    <small class="text-muted">{{ gift_note }} </small>
                  </div>
                  <span class="text-muted" v-on:click="step = 2">Edit</span>
                </li>
              </ul>
            </div>
            <div class="bg-light p-3 border border-radius">
              <h4 class="mb-3">Payment</h4>
              <img src="@/assets/visa.svg" class="card-icon" />
              <img src="@/assets/master.svg" class="card-icon" />
              <img src="@/assets/disc.svg" class="card-icon" />
              <img src="@/assets/dc.svg" class="card-icon" />
              <img src="@/assets/amex.svg" class="card-icon" />
              <hr />
              <Card />
              <span class="error-text">{{ payment_error }}</span>
              <button
                class="btn bg-dark btn-block text-white btn-lg mt-3"
                @click="purchaseHandler"
                v-if="!processing"
              >
                Finish & Pay
              </button>
              <button
                class="btn bg-dark btn-block text-white btn-lg mt-3"
                v-if="processing"
              >
                <img src="@/assets/rings.svg" /> Processing Payment...
              </button>
              <div class="text-right">
                <small class="text-muted"
                  ><svg
                    viewBox="0 0 11 17"
                    aria-hidden="true"
                    style="width: 1rem; height: 0.75rem"
                  >
                    <path
                      d="M3 6.5V3.4C3 2.1 4.1 1 5.5 1S8 2.1 8 3.4v3.1c0 .3.2.5.5.5s.5-.2.5-.5V3.4C9 1.5 7.4 0 5.5 0S2 1.5 2 3.4v3.1c0 .3.2.5.5.5s.5-.2.5-.5zM9.8 7H1.2C.6 7 0 7.6 0 8.3v7.4c0 .7.6 1.3 1.2 1.3h8.5c.7 0 1.2-.6 1.2-1.3V8.3C11 7.6 10.4 7 9.8 7zm.2 8.7c0 .1-.1.3-.2.3H1.2c-.1 0-.2-.1-.2-.3V8.3c0-.1.1-.3.2-.3h8.5c.1 0 .2.1.2.3v7.4z"
                    ></path>
                    <path
                      d="M5.5 10c-.3 0-.5.3-.5.6v2.9c0 .2.2.5.5.5s.5-.3.5-.6v-2.9c0-.2-.2-.5-.5-.5z"
                    ></path></svg
                  >Secure Transaction
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var base_url = process.env.VUE_APP_BASE_FUNCTIONS_URL;
import Card from "@/components/Card.vue";
const axios = require("axios");
export default {
  name: "Home",
  components: {
    Card,
  },
  data() {
    return {
      step: 1,
      estimate: {},
      product_name: "",
      product_price: null,
      tax_amount: 0,
      discount_amount: 0,
      amount_due: null,
      loadingCart: false,
      showPromoInput: false,
      validatingCoupon: false,
      processing: false,
      coupon: "",
      payment_error: "",
      validatedCoupon: "",
      invalidCoupon: "",
      coupon_error: "",
      expanded_total: false,
      cardholder_name: "",
      card_token: "",
      card_expiry_month: "",
      card_expiry_year: "",
      card_type: "",
      billing_address: {
        email: "",
      },
      is_gift: false,
      gift_note: "",
      shipping_address: {
        first_name: "",
        last_name: "",
        line1: "",
        line2: "",
        city: "",
        state_code: "Alabama",
        zip_code: "",
        country: "US",
      },
      gift_recipient_name: "",
      gift_recipient_email: "",
      errorMessage: "",
    };
  },
  mounted() {
    var self = this;
    this.initTotal();
    Spreedly.init();

    Spreedly.on("ready", function (frame) {
      Spreedly.setFieldType("number", "tel");
      Spreedly.setFieldType("cvv", "tel");
      Spreedly.setPlaceholder("number", "4111 1111 1111 1111");
      Spreedly.setPlaceholder("cvv", "CVV");
      Spreedly.setNumberFormat("prettyFormat");
      Spreedly.setStyle("number", "width:100%; color:#333; opacity:0.9;");
      Spreedly.setStyle("cvv", "width:100%; color:#333; opacity:0.9;");
    });

    Spreedly.on("errors", function (errors) {
      self.processing = false;
      for (var i = 0; i < errors.length; i++) {
        var error = errors[i];

        if (
          error["attribute"] == "first_name" ||
          error["attribute"] == "last_name"
        ) {
          self.payment_error = "Please enter a valid name.";
        } else {
          self.payment_error = error["message"];
        }
        console.log(error);
      }
    });
    Spreedly.on("paymentMethod", function (token, pmData) {
      self.card_token = pmData["token"];
      self.cardholder_name = pmData["full_name"];
      self.card_expiry_month = pmData["month"];
      self.card_expiry_year = pmData["year"];
      self.card_type = pmData["card_type"];
      self.buy();
    });
  },
  methods: {
    toggleSummary() {
      this.expanded_total = this.expanded_total == false ? true : false;
    },
    stepOneHandler() {
      this.estimateTotal();
      this.step = 2;
    },
    paymentError() {
      console.log("something");
    },
    stepTwoHandler() {
      this.paymentError = "";
      this.step = 3;
    },
    togglePromoInput() {
      this.showPromoInput = this.showPromoInput ? false : true;
    },
    couponHandler() {
      this.validatingCoupon = true;
      this.updateCoupon();
    },
    removeCoupon() {
      this.coupon = "";
      this.discount_amount = 0;
      this.validatedCoupon = "";
      this.estimateTotal();
      this.showPromoInput = false;
    },
    purchaseHandler() {
      this.processing = true;
      var requiredFields = {};
      requiredFields["full_name"] = document.getElementById(
        "checkout_credit_card_name"
      ).value;
      requiredFields["month"] = document.getElementById(
        "checkout_credit_card_month"
      ).value;
      requiredFields["year"] = document.getElementById(
        "checkout_credit_card_year"
      ).value;

      Spreedly.tokenizeCreditCard(requiredFields);
    },
    initTotal() {
      var plan_id = this.$store.state.plan_id;
      var plan = this.$store.state.plans[plan_id];
      this.product_name = plan["product_name"];
      this.product_price = plan["product_price"];
      this.tax_amount = 0;
      this.discount_amount = 0;
      this.amount_due = plan["product_price"];
    },
    updateCoupon() {
      var plan_id = this.$store.state.plan_id;
      axios
        .post(process.env.VUE_APP_BASE_FUNCTIONS_URL + "/applyCoupon", {
          product_sku: plan_id,
          state_code: this.shipping_address.state_code,
          coupon_id: this.coupon,
        })
        .then((res) => {
          if (res.data.error == false) {
            this.invalidCoupon = false;
            this.discount_amount = res.data.total_discount;
            this.tax_amount = res.data.total_tax;
            this.amount_due = res.data.amount_due;
            this.validatedCoupon = this.coupon;
            this.validatingCoupon = false;
          } else {
            this.invalidCoupon = true;
            this.validatingCoupon = false;
          }
        })
        .catch((err) => {
          this.invalidCoupon = true;
          this.validatingCoupon = false;
        });
    },
    estimateTotal() {
      var plan_id = this.$store.state.plan_id;
      axios
        .post(process.env.VUE_APP_BASE_FUNCTIONS_URL + "/estimateTotal", {
          product_sku: plan_id,
          state_code: this.shipping_address.state_code,
          coupon_id: this.validatedCoupon,
        })
        .then((res) => {
          this.discount_amount = res.data.total_discount;
          this.tax_amount = res.data.total_tax;
          this.amount_due = res.data.amount_due;
        })
        .catch((err) => {
          this.invalidCoupon = true;
          this.validatingCoupon = false;
        });
    },
    buy() {
      var self = this;
      var plan_id = this.$store.state.plan_id;
      axios
        .post(process.env.VUE_APP_BASE_FUNCTIONS_URL + "/createSubscription", {
          cardholder_name: this.cardholder_name,
          card_token: this.card_token,
          card_expiry_month: this.card_expiry_month,
          card_expiry_year: this.card_expiry_year,
          card_type: this.card_type,
          coupon_id: this.validatedCoupon,
          billing_email_address: this.billing_address.email,
          first_name: this.shipping_address.first_name,
          last_name: this.shipping_address.last_name,
          line1: this.shipping_address.line1,
          line2: this.shipping_address.line2,
          city: this.shipping_address.city,
          state: this.shipping_address.state_code,
          zip_code: this.shipping_address.zip_code,
          product_sku: plan_id,
          renew: this.$store.state.renew,
          gift_note: this.gift_note,
          gift_recipient_name: this.gift_recipient_name,
          gift_recipient_email: this.gift_recipient_email,
          start_date: this.$store.state.send_on,
        })
        .then((res) => {
          this.processing = false;
          var order_id = 0;
          if (res.data.paymentSucceeded == true || res.data.success == true) {
            if (res.data.order_id) {
              order_id = res.data.order_id;
            }
            this.$router.push({
              path: "/thanks",
              query: {
                customer_name: this.cardholder_name,
                billing_email_address: this.billing_address.email,
                first_name: this.shipping_address.first_name,
                last_name: this.shipping_address.last_name,
                unit_amount: this.product_price,
                amount_due: this.amount_due,
                tax_amount: this.tax_amount,
                discount_amount: this.discount_amount,
                line1: this.shipping_address.line1,
                line2: this.shipping_address.line2,
                city: this.shipping_address.city,
                state: this.shipping_address.state_code,
                zip_code: this.shipping_address.zip_code,
                product_name: this.product_name,
                renew: true,
                order_id: order_id,
                start_date: this.$store.state.send_on,
              },
            });
            var evnt_data = {
              event: "purchase",
              transactionId: order_id,
              transactionAffiliation: "Craft Jerky Co",
              transactionTotal: this.amount_due / 100,
              transactionTax: this.tax_amount / 100,
              transactionShipping: 0,
              transactionProducts: [
                {
                  sku: this.product_name,
                  name: this.product_name,
                  price: this.product_price/100,
                  quantity: 1,
                },
              ],
            };
            window.dataLayer = window.dataLayer || [];
            dataLayer.push(evnt_data);
          } else {
            this.processing = false;
            var err_msg = res.data.reason;
            if (err_msg) {
              self.payment_error = "Payment failed. " + err_msg;
            } else {
              self.payment_error =
                "Payment failed. Please check your card details and try again.";
            }
          }
        })
        .catch((res) => {
          this.processing = false;
          self.payment_error = "Payment failed.";
        });
    },
  },
  filters: {
    currency(value) {
      return (value / 100).toFixed(2);
    },
    formatDate(value) {
      var d = new Date(value * 1).toLocaleDateString();
      return d;
    },
  },
  computed: {
    isAGift() {
      if (
        this.gift_recipient_name ||
        this.gift_recipient_email ||
        this.gift_note
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss">
.field {
  border: 1px solid #ccc;
  margin-top: 5px;
  border-radius: 5px;
}
.error-text {
  font-size: 14px;
  line-height: 14px;
  color: red;
}
.help-text {
  color: #2f2f2f;
  font-size: 12px;
  display: block;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #333;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.forumlate-input-errors {
  list-style: none;
  list-style-type: none;
  padding: 0;
}
</style>
