import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'
import VueFormulate from '@braid/vue-formulate'
import LogRocket from 'logrocket';
LogRocket.init('762nj4/cjc');

Vue.config.productionTip = false

Vue.use(VueFormulate, {
  classes: {
    outer: '',
    input (context) {
      switch (context.classification) {
        case 'button':
          return 'btn bg-dark btn-block text-white btn-lg mt-2'
        default:
          return 'form-control my-2 p-3'
      }
    },
    label: 'd-none',
    help: 'help-text mb-1',
    error: 'error-text',
    errors: 'list-unstyled'
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

